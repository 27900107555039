import React, { PureComponent } from "react";
import styled from "styled-components";

const API_URL = "https://qu0e96115h.execute-api.eu-west-1.amazonaws.com/prod/";

const Container = styled.div`
  max-width: 80%;
  text-align: center;
  margin: 0 auto;
`;

const Option = styled.a`
  border: 10px solid black;
  border-radius: 5px;
  padding: 10px;
  display: block;
  font-size: 34px;
  color: black;
  text-decoration: none;
  text-align: center;
  transition: 0.2s all;
  width: 300px;
  margin: 0 auto;
  cursor: ${props => (props.voted ? "not-allowed" : "pointer")};

  &:hover {
    background-color: orange;
  }
`;

const Small = styled.small`
  font-size: 11px;
  margin-top: -10px;
`;

const Video = styled.video`
  margin-top: 100px;
  transition: 0.2s all;

  opacity: ${props => (props.visible ? 1 : 0)};
`;

class App extends PureComponent {
  state = {
    votes: 0,
    voted: false
  };

  componentDidMount() {
    fetch(API_URL)
      .then(res => res.json())
      .then(({ votes }) => {
        this.setState({ votes });
      });
  }

  vote(e) {
    const { voted } = this.state;
    e.preventDefault();
    if (voted) return;

    fetch(`${API_URL}/vote`, { method: "post" })
      .then(res => res.json())
      .then(({ votes }) => {
        this.setState({ votes, voted: true });
        this.video.play();
      });
  }

  render() {
    const { votes, voted } = this.state;

    return (
      <Container>
        <h1>Wat is de leukste rivier van Nederland?</h1>
        <h2>{voted ? "Bedankt om te stemmen" : "Stem hier"} </h2>
        <Option voted={voted} href="#" onClick={e => this.vote(e)}>
          De Waal
          <br />
          <Small>{votes} stemmen</Small>
        </Option>
        <Video
          visible={voted}
          src={require("./dewaal.mp4")}
          ref={ref => (this.video = ref)}
          loop
        />
      </Container>
    );
  }
}

export default App;
